<template>
  <div class="newsbox">
    <img src="../assets/img/1617001828(1).jpg" class="bg" alt="" />
    <img src="../assets/img/1617001828(1).jpg" class="bg1" alt="" />
    <Nav />
    <div class="banner">
      <img :src="bannerUrl" alt="" />
    </div>
    <div class="common content">
      <div
        style="font-weight: bold;
               color: #888888;
               font-size: 26px;margin-bottom:44px"
      >
        <span
          class="iconfont iconweizhi"
          style="font-size:30px;color:#FD3C22"
        ></span>
        首页-活动新闻-详情
      </div>
      <div
        style="font-size: 28px;
               font-family: Source Han Serif SC;
               font-weight: 500;
               color: #262626;"
      >
        你好，ICT渠道，你好，中邮！
      </div>
      <div class="newsTime">
        <span class="iconfont iconshijian"></span>
        2021-03-10
      </div>
      <p>
        渠道核心企业中国邮电器材集团有限公司与雨林开展异业合作，共同发布合作新品。
      </p>
      <br />
      <p>
        {{
          `中国邮电器材集团公司（以下简称中邮）成立于1964年，先后归属于邮电部、信息产业部、中央企业工委，现为世界500强中国通用技术(集团)控股有限责任公司管理的全资子企业。是三大运营商以及华为手机生产厂商最重要的战略合作伙伴，在通信产业链中发挥着重要作用。中邮构建广泛、立体的销售渠道网络，以全方位、优质的服务连续多年信息通信终端市场份额名列前茅，链接10万余家客户，与40多万个门店建立了紧密的合作关系，年触达消费者超2亿人，具有强大的渠道号召力及影响力。自集团创立以来遵循“合作共赢”、“创造和分享价值”的发展原则，以品牌为先导，渠道为依托，为消费者分享高品质的产品，为经销商提供完善的服务体系。这与雨林集团致力于引领中国茶产业发展至国际水平，提升并弘扬中华优秀茶文化的精神高度契合，双方于2020年11年联合推出“生来”古树茶系列产品线，“生来”古树茶系列产品以卓越不凡的自然生态，严守标准的制茶工艺，无限百搭的品饮场景，布局通信用户新领域消费者，打造1+1>2的全新茶饮消费模式，让千万家庭品享优质好茶，跨界合作，让雨林古树茶精彩出圈。“生来好茶，为你而生”！`
        }}
      </p>
      <br />
      <br />
      <div style="height:510px;width:100%;overflow:hidden">
        <img
          style="width:100%"
          src="https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/newsd1.jpg"
          alt=""
        />
      </div>
      <br />
      <br />
      <p>
        云南是世界唯一存在连片古茶园的茶产地，拥有古树园面积329.68万亩，是世代相传的自然宝藏。雨林古树茶“普洱茶制作技艺”
        被国家列为勐海县非物质文化遗产。“生来”系列古树茶正是来自于云南西双版纳原始雨林，拥有自然的生态环境，孕育出内质丰厚的古
        茶树鲜叶，让茶品滋味更加的饱满，更耐冲泡。在茶山建立起一线品控车间、以“一芽二叶”作为最低采摘标准、采用传统制茶工艺、当
        地有丰富经验的制茶师傅进行加工、遵循严苛的生产标准，保证了茶品的质量。
      </p>
      <br />
      <br />
      <div style="height:510px;width:100%;overflow:hidden">
        <img
          style="width:100%"
          src="https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/newsd2.jpg"
          alt=""
        />
      </div>
      <br />
      <br />
      <p>
        中邮与雨林强强联合，优中选优，为消费者量身打造了生来系列产品，独创专为手机用户设计的三种规格：32g/64g/128g，让产品
        统一标准化，减少消费者的选择困扰，同时采用体积小巧的独立包装，方便携带，冲泡方便，让快节奏的生活中，随时随地享受到温暖的
        茶香。“生来”系列古树茶，生来好茶，为你而生，秉承让千万家庭品享优质好茶，让茶饮体验更加大众化的服务理念，让更多人感受古
        树茶的魅力。
      </p>
      <br />
    </div>
    <div class="common foot">
      <div class="back" @click="back">返回列表</div>
      <div class="jump">
        <div>上一篇: 没有上一篇了</div>
        <div>下一篇: 没有下一篇了</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Nav from "../components/Nav.vue";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      bannerUrl:
        "https://shenglai.oss-cn-shenzhen.aliyuncs.com/image/生来新闻.png",
    };
  },
  methods: {
    back() {
      this.$router.push("/news");
    },
  },
};
</script>

<style lang="less" scoped>
.common {
  margin: 0 auto;
}
.newsbox {
  position: relative;
  .bg {
    position: absolute;
    top: 842px;
    right: 0;
    width: 300px;
  }
  .bg1 {
    position: absolute;
    left: 0;
    bottom: 300px;
    width: 300px;
  }
}
.banner {
  width: 100%;
  height: 842px;
  background-size: 100% 842px;
  margin-bottom: 60px;
  img {
    width: 100%;
    height: 100%;
  }
}
.content {
  width: 1200px;
  padding-bottom: 30px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 20px;
  .newsTime {
    margin-top: 25px;
    margin-bottom: 20px;
    font-size: 20px;
    color: #666666;
  }
  p {
    text-indent: 35px;
    font-size: 20px;
    font-family: Source Han Serif SC;
    font-weight: 200;
    color: #444444;
    line-height: 30px;
    word-break: break-all;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.foot {
  width: 1200px;
  margin-bottom: 135px;
  .back {
    display: inline-block;
    padding: 0 10px;
    border-right: 1px solid #bf1625;
    border-left: 1px solid #bf1625;
    font-size: 24px;
    font-family: Source Han Serif SC;
    font-weight: 500;
    color: #bf1625;
  }
  .back:hover {
    cursor: pointer;
  }
  .jump {
    margin-top: 18px;
    text-indent: 10px;
    font-size: 20px;
    font-family: Source Han Serif SC;
    font-weight: 500;
    color: #666666;
    div:hover {
      cursor: pointer;
      color: #bf1625;
    }
  }
}
</style>
